import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  TroubleshootingKey,
  troubleshootingKeys,
} from '../../models/TroubleshootingKey';
import TroubleshootingMessage from './TroubleshootingMessage';
import ChecklistNodeButton from './ChecklistNodeButton';
import IndexedDbCertificateEntry from '../../models/IndexedDbCertificateEntry';
import {
  getDisplayName,
  getFriendlyNameWithoutSuffix,
  isSignCertificate,
  isSslCertificate,
} from '../../utils/certificates';
import { getWebCryptoSecurity } from '../../utils/global-variables';
import { download } from '../../utils/download';

interface TroubleshootingProps {
  anchor?: TroubleshootingKey;
}

const Troubleshooting: React.FC<TroubleshootingProps> = (
  props: TroubleshootingProps,
) => {
  const intl = useIntl();
  const getI18n = (id: string, values?: any) =>
    intl.formatMessage({ id: id }, values);

  const downloadDummyP12File = () => {
    download('test', 'empty-test-download.p12', 'application/x-pkcs12');
  };

  const handleMakeKeysUnextractableClick = async () => {
    const certificates: Array<IndexedDbCertificateEntry> =
      await getWebCryptoSecurity().getCertificates('friendlyName');
    const extractableKeys: Array<String> = [];
    const isExtractable = (certificate: IndexedDbCertificateEntry) =>
      certificate.isPrivateKeyExtractable &&
      (isSignCertificate(certificate) || isSslCertificate(certificate));
    for (const certificate of certificates) {
      if (isExtractable(certificate)) {
        const displayName = getDisplayName(certificate);
        if (extractableKeys.includes(displayName)) {
          continue;
        }
        alert(
          getI18n('support.page.extractable.keys.found', {
            displayName: displayName,
          }),
        );
        extractableKeys.push(displayName);
        await getWebCryptoSecurity().protectSslAndSignPrivateKeysAgainstExtraction(
          getFriendlyNameWithoutSuffix(certificate),
        );
        alert(
          getI18n('support.page.extractable.keys.now.protected', {
            displayName: displayName,
          }),
        );
      }
    }

    if (extractableKeys.length === 0) {
      alert(getI18n('support.page.no.extractable.keys.found'));
    }
  };

  const handleClearIndexDbClick = () => {
    if (window.confirm(getI18n('support.page.confirm.delete.indexed.db'))) {
      window.indexedDB.deleteDatabase('SecurityIDB');
    }
  };

  return (
    <>
      <h3>
        <FormattedMessage id="support.page.troubleshooting.header" />
      </h3>
      {troubleshootingKeys.map(key => (
        <TroubleshootingMessage i18nKey={key} isSelected={key === props.anchor}>
          {key === TroubleshootingKey.CLEAR_INDEXED_DB && (
            <ChecklistNodeButton
              onClick={handleClearIndexDbClick}
              i18nKey="support.page.button.clear.indexeddb"
            />
          )}
          {key === TroubleshootingKey.MAKE_KEYS_UNEXTRACTABLE && (
            <ChecklistNodeButton
              onClick={handleMakeKeysUnextractableClick}
              i18nKey="support.page.button.make.keys.unextractable"
            />
          )}
          {key === TroubleshootingKey.TEST_P12_DOWNLOAD && (
            <ChecklistNodeButton
              onClick={downloadDummyP12File}
              i18nKey="support.page.button.download.test.p12"
            />
          )}
        </TroubleshootingMessage>
      ))}
    </>
  );
};

export default Troubleshooting;
