import React, { ChangeEvent } from 'react';
import CertificateEntry from './CertificateEntry';
import '../../css/components/CertificateSelector.css';

import NewCertificateEntryForm from './NewCertificateEntryForm';
import {
  getIndexedDbCertificates,
  removeCertificates,
  setActiveCertificate,
  unsetActiveCertificate,
} from '../../utils/reactWebCryptoAdapter';
import { FormattedMessage } from 'react-intl';
import {
  decodeFriendlyName,
  encodeFriendlyName,
  friendlyNameContainsKeywords,
  friendlyNameContainsOnlyAllowedSymbols,
  friendlyNameIsTooLong,
  getDisplayName,
  getFriendlyNameWithoutSuffix,
  getOutdatedCertificates,
  MAX_FRIENDLY_NAME_LENGTH,
} from '../../utils/certificates';
import { onEnterKeyPress } from '../../utils/enter-key-listener';
import Certificate from '../../models/Certificate';
import Alert, { AlertProps, AlertType } from '../common/Alert';
import Loader from '../common/Loader';
import BoxBody from '../common/BoxBody';
import BoxFooter from '../common/BoxFooter';
import XactFooterButton from '../common/XactFooterButton';
import { getWebCryptoSecurity } from '../../utils/global-variables';
import ImportCertificatesButton from './ImportCertificatesButton';
import SupportPageButton from './SupportPageButton';

interface CertificateSelectorProps {
  handleCertificateSelection: (selectedCertificate?: Certificate) => void;
}

interface CertificateSelectorState {
  certificates: Array<Certificate>;
  selectedCertificate?: Certificate;
  isLoaded: boolean;
  error?: string;
  certificatesFile?: File;
  certificatesFriendlyNameValidationAlertProps?: AlertProps;
  certificatesValidationAlertProps?: AlertProps;
  newCertificateFriendlyName: string;
  newCertificateImportPassword?: string;
  hasNewCertificateValidPassword?: boolean;
  passwordNotWorking?: boolean;
  isImportSuccessful?: boolean;
}

class CertificateSelector extends React.Component<
  CertificateSelectorProps,
  CertificateSelectorState
> {
  private newCertificateFriendlyNameInput = React.createRef<HTMLInputElement>();
  private passwordInvalidTimeoutId: any = null;
  private emailOtpVisible: boolean = false;

  constructor(props: CertificateSelectorProps) {
    super(props);
    this.state = {
      isLoaded: false,
      certificates: [],
      newCertificateFriendlyName: '',
    };

    const urlParams = new URLSearchParams(window.location.search);
    this.emailOtpVisible = urlParams.get('emailOtpVisible') === 'true';

    this.onCertificateClick = this.onCertificateClick.bind(this);
    this.handleNewCertificateFriendlyNameChange =
      this.handleNewCertificateFriendlyNameChange.bind(this);
    this.handleImportPasswordChange = this.handleImportPasswordChange.bind(this);
    this.onValidP12ImportPassword = this.onValidP12ImportPassword.bind(this);
    this.importCertificatesFromFile = this.importCertificatesFromFile.bind(this);
    this.cleanUpNewCertificateState = this.cleanUpNewCertificateState.bind(this);
    this.validateNewCertificate = this.validateNewCertificate.bind(this);
  }

  componentDidMount() {
    Promise.all([this.loadIndexedDbCertificates()]).finally(
      this.deleteOutdatedCertificates,
    );
  }

  private loadIndexedDbCertificates = () => {
    this.setState({ isLoaded: false } as CertificateSelectorState);
    return getIndexedDbCertificates(true)
      .then(certificates =>
        this.setState({
          certificates: certificates,
          selectedCertificate: certificates[0],
        } as CertificateSelectorState),
      )
      .catch(reason =>
        this.setState({
          error: reason?.message || reason,
        } as CertificateSelectorState),
      )
      .finally(() => this.setState({ isLoaded: true } as CertificateSelectorState));
  };

  private cleanUpNewCertificateState() {
    this.clearPasswordInvalidTimeout();
    this.setState({
      certificatesFile: undefined,
      newCertificateImportPassword: undefined,
      newCertificateFriendlyName: '',
      hasNewCertificateValidPassword: undefined,
      certificatesFriendlyNameValidationAlertProps: undefined,
      certificatesValidationAlertProps: undefined,
      passwordNotWorking: false,
    } as CertificateSelectorState);
  }

  private onCertificateClick(certificate: Certificate) {
    this.setState({ selectedCertificate: certificate } as CertificateSelectorState);
  }

  handleCertificateSelectionSubmit = async () => {
    if (!this.state.selectedCertificate) {
      await unsetActiveCertificate();
      return;
    }
    await setActiveCertificate(this.state.selectedCertificate);
    this.props.handleCertificateSelection(this.state.selectedCertificate);
  };

  handleCertificatesImport = (event: any) => {
    event.preventDefault();
    this.setCertificatesFile(event.target.files[0]);
  };

  handleCertificatesDrop = (event: any) => {
    event.preventDefault();
    this.setCertificatesFile(event.dataTransfer?.files[0]);
  };

  setCertificatesFile = (file: File | undefined) => {
    if (file?.name.toLowerCase().endsWith('.p12') && !this.validateP12File(file)) {
      return;
      // TODO JWA implement PEM checks also (once PEM is encrypted)
    }
    this.setState(
      {
        certificatesFile: file,
        isImportSuccessful: undefined,
      } as CertificateSelectorState,
      () => {
        // TODO JWA fix properly
        if (this.state.certificatesFile?.name.toLowerCase().endsWith('.pem')) {
          this.newCertificateFriendlyNameInput.current!.focus();
          this.newCertificateFriendlyNameInput.current!.onkeypress = (
            e: KeyboardEvent,
          ) => onEnterKeyPress(e, this.importCertificatesFromFile);
          this.newCertificateFriendlyNameInput.current!.placeholder =
            'Enter friendly name...';
        }
      },
    );
  };

  validateP12File = (file: File | undefined): boolean => {
    if (this.state.certificatesFile) {
      this.setState({
        certificatesValidationAlertProps: {
          type: AlertType.Error,
          i18nKey: 'certificates.p12.already.opened',
        },
      } as CertificateSelectorState);
      return false;
    }

    if (!file || file.size < 1) {
      this.setState({
        certificatesValidationAlertProps: {
          type: AlertType.Error,
          i18nKey: 'certificates.p12.empty',
        },
      } as CertificateSelectorState);
      return false;
    }

    const p12FileTypes = ['application/x-pkcs12', 'application/pkcs12'];
    if (!p12FileTypes.includes(file.type)) {
      console.warn('File type:', file.type);
      this.setState({
        certificatesValidationAlertProps: {
          type: AlertType.Warning,
          i18nKey: 'certificates.p12.unexpected.type',
          i18nValues: { type: file.type },
        },
      } as CertificateSelectorState);
      return true;
    }
    this.setState({
      certificatesValidationAlertProps: undefined,
    } as CertificateSelectorState);
    return true;
  };

  private handleNewCertificateFriendlyNameChange(
    event: ChangeEvent<HTMLInputElement>,
  ) {
    this.setState({
      newCertificateFriendlyName: event.target.value,
    } as CertificateSelectorState);
  }

  private resetPasswordInvalidTimeout = () => {
    this.setState({ passwordNotWorking: false } as CertificateSelectorState);
    this.clearPasswordInvalidTimeout();
    this.passwordInvalidTimeoutId = setTimeout(
      () => this.setState({ passwordNotWorking: true } as CertificateSelectorState),
      3000,
    );
  };

  private clearPasswordInvalidTimeout = () => {
    clearTimeout(this.passwordInvalidTimeoutId);
  };

  /* TODO JWA Currently only P12 passwords are checked. Once encrypted PEMs are importable this needs to be reviewed. */
  private handleImportPasswordChange(event: ChangeEvent<HTMLInputElement>) {
    this.resetPasswordInvalidTimeout();
    const importPassword = event.target.value;
    this.setState({
      newCertificateImportPassword: importPassword,
    } as CertificateSelectorState);
    getWebCryptoSecurity().getCertificateInformationFromP12WithoutImporting(
      importPassword,
      this.state.certificatesFile,
      this.onValidP12ImportPassword,
    );
  }

  /* Special Import Handler for P12 Files only */
  private onValidP12ImportPassword(certificate: Certificate) {
    this.clearPasswordInvalidTimeout();
    this.setState({ passwordNotWorking: false } as CertificateSelectorState);
    if (!this.validateNewCertificate(certificate)) {
      return;
    }
    const p12Filename = this.state.certificatesFile!.name;
    const temporaryP12FilenamePrefix: string = 'xact-temp-';
    const isTemporaryCertificate: boolean = p12Filename.startsWith(
      temporaryP12FilenamePrefix,
    );
    const filenameWithoutExtension: string = isTemporaryCertificate
      ? ''
      : decodeFriendlyName(p12Filename.slice(0, -4));
    this.setState({
      hasNewCertificateValidPassword: true,
      newCertificateFriendlyName: filenameWithoutExtension,
    } as CertificateSelectorState);
    this.newCertificateFriendlyNameInput.current!.focus();
    this.newCertificateFriendlyNameInput.current!.onkeypress = (e: KeyboardEvent) =>
      onEnterKeyPress(e, this.importCertificatesFromFile);
    this.newCertificateFriendlyNameInput.current!.placeholder =
      'Enter friendly name...';
  }

  private validateNewCertificateFriendlyName(friendlyName: string): boolean {
    if (!friendlyName) {
      this.setState({
        certificatesFriendlyNameValidationAlertProps: {
          type: AlertType.Error,
          i18nKey: 'certificate.selector.new.certificate.friendly.name.required',
        },
      } as CertificateSelectorState);
      return false;
    }
    if (friendlyNameIsTooLong(friendlyName)) {
      this.setState({
        certificatesFriendlyNameValidationAlertProps: {
          type: AlertType.Error,
          i18nKey:
            'certificate.selector.new.certificate.friendly.name.name.is.too.long',
          i18nValues: { max_length: MAX_FRIENDLY_NAME_LENGTH },
        },
      } as CertificateSelectorState);
      return false;
    }
    const allCertificates: Array<Certificate> = this.state.certificates;
    const allCertificatesFriendlyNameWithoutSuffixList: Array<string> =
      allCertificates.map(c => getFriendlyNameWithoutSuffix(c));
    const isAlreadyUsed: boolean =
      allCertificatesFriendlyNameWithoutSuffixList.includes(friendlyName);
    if (isAlreadyUsed) {
      this.setState({
        certificatesFriendlyNameValidationAlertProps: {
          type: AlertType.Error,
          i18nKey: 'certificate.selector.new.certificate.friendly.name.name.in.use',
        },
      } as CertificateSelectorState);
      return false;
    }
    if (!friendlyNameContainsOnlyAllowedSymbols(friendlyName)) {
      this.setState({
        certificatesFriendlyNameValidationAlertProps: {
          type: AlertType.Error,
          i18nKey:
            'certificate.selector.new.certificate.friendly.name.character.constraint',
        },
      } as CertificateSelectorState);
      return false;
    }
    if (friendlyNameContainsKeywords(friendlyName)) {
      this.setState({
        certificatesFriendlyNameValidationAlertProps: {
          type: AlertType.Error,
          i18nKey:
            'certificate.selector.new.certificate.friendly.name.keyword.constraints',
        },
      } as CertificateSelectorState);
      return false;
    }
    this.setState({
      certificatesFriendlyNameValidationAlertProps: undefined,
    } as CertificateSelectorState);
    return true;
  }

  private validateNewCertificate(newCertificate: Certificate): boolean {
    const newCertificateCommonName: string = newCertificate.subject.CN;
    const allCertificates: Array<Certificate> = this.state.certificates;
    const existingCertificate = allCertificates.find(
      (certificate: Certificate) =>
        certificate.subject.CN === newCertificateCommonName,
    );
    if (!existingCertificate) {
      this.setState({
        certificatesFriendlyNameValidationAlertProps: undefined,
      } as CertificateSelectorState);
      return true;
    }
    const existingCertificateFriendlyName: string =
      getDisplayName(existingCertificate);
    const alertI18nValues: object = {
      friendlyName: existingCertificateFriendlyName,
    };
    if (existingCertificate.serialNumber < newCertificate.serialNumber) {
      this.setState({
        certificatesFriendlyNameValidationAlertProps: {
          type: AlertType.Warning,
          i18nKey: 'certificate.selector.older.certificate.version.present',
          i18nValues: alertI18nValues,
        },
      } as CertificateSelectorState);
      return true;
    }
    if (existingCertificate.serialNumber > newCertificate.serialNumber) {
      this.setState({
        certificatesFriendlyNameValidationAlertProps: {
          type: AlertType.Error,
          i18nKey: 'certificate.selector.newer.certificate.version.present',
          i18nValues: alertI18nValues,
        },
      } as CertificateSelectorState);
    } else if (existingCertificate.serialNumber === newCertificate.serialNumber) {
      this.setState({
        certificatesFriendlyNameValidationAlertProps: {
          type: AlertType.Error,
          i18nKey: 'certificate.selector.certificate.already.present',
          i18nValues: alertI18nValues,
        },
      } as CertificateSelectorState);
    }
    return false;
  }

  private importCertificatesFromFile() {
    const friendlyName = this.state.newCertificateFriendlyName;
    if (!this.validateNewCertificateFriendlyName(friendlyName)) {
      return;
    }
    // In the IndexedDB we should not have spaces in the name
    const friendlyNameWithReplacedWhitespaces = encodeFriendlyName(friendlyName);
    const importPassword = this.state.newCertificateImportPassword;
    const certificatesFile = this.state.certificatesFile;
    const callback = (importSuccessful: boolean) => {
      this.setState({
        isImportSuccessful: importSuccessful,
      } as CertificateSelectorState);
      this.cleanUpNewCertificateState();
      this.loadIndexedDbCertificates();
    };

    if (this.state.certificatesFile?.name.toLowerCase().endsWith('.pem')) {
      getWebCryptoSecurity().importPemFromFileInputElement(
        friendlyNameWithReplacedWhitespaces,
        certificatesFile,
        callback,
      );
    } else {
      getWebCryptoSecurity().importP12FromFileInputElement(
        friendlyNameWithReplacedWhitespaces,
        importPassword,
        certificatesFile,
        callback,
      );
    }
  }

  private renderAlerts() {
    const alerts: Array<JSX.Element> = [];
    const allCertificates: Array<Certificate> = this.state.certificates;
    const addSuccessAlert = (i18nKey: string) =>
      alerts.push(
        <Alert key={i18nKey} type={AlertType.Success} i18nKey={i18nKey} />,
      );
    const addWarningAlert = (i18nKey: string) =>
      alerts.push(
        <Alert key={i18nKey} type={AlertType.Warning} i18nKey={i18nKey} />,
      );
    const addErrorAlert = (i18nKey: string) =>
      alerts.push(<Alert key={i18nKey} type={AlertType.Error} i18nKey={i18nKey} />);
    if (this.state.error) {
      alerts.push(<Alert type={AlertType.Error} i18nKey={this.state.error} />);
    }
    if (this.state.isImportSuccessful !== undefined) {
      this.state.isImportSuccessful
        ? addSuccessAlert('certificate.selector.import.success')
        : addErrorAlert('certificate.selector.import.failed');
    }
    if (this.state.passwordNotWorking) {
      addWarningAlert('certificate.selector.password.not.working');
    }
    if (this.state.certificatesFriendlyNameValidationAlertProps) {
      alerts.push(
        <Alert {...this.state.certificatesFriendlyNameValidationAlertProps} />,
      );
    }
    if (this.state.certificatesValidationAlertProps) {
      alerts.push(<Alert {...this.state.certificatesValidationAlertProps} />);
    }
    if (allCertificates.length === 0) {
      addWarningAlert('certificate.selector.no.certificates.found');
    }
    if (allCertificates.some(certificate => certificate.isPrivateKeyExtractable)) {
      addWarningAlert('certificate.selector.extractable.keys.detected');
    }
    if (allCertificates.some(c => c.willExpireSoon)) {
      addWarningAlert('certificate.selector.certificate.expires.soon');
    }
    if (allCertificates.some(c => c.isExpired)) {
      addErrorAlert('certificate.selector.certificate.has.expired');
    }
    return alerts;
  }

  deleteOutdatedCertificates = () => {
    const certificates: Array<Certificate> = this.state.certificates;
    const outdatedCertificates: Array<Certificate> =
      getOutdatedCertificates(certificates);
    if (outdatedCertificates.length < 1) {
      return;
    }
    this.setState(prevState => {
      const filtered = prevState.certificates.filter(
        cert => !outdatedCertificates.includes(cert),
      );
      return {
        certificates: filtered,
        selectedCertificate:
          prevState.selectedCertificate &&
          !outdatedCertificates.includes(prevState.selectedCertificate)
            ? prevState.selectedCertificate
            : filtered[0],
      } as CertificateSelectorState;
    });
    Promise.all(
      outdatedCertificates.map(cert =>
        removeCertificates(getFriendlyNameWithoutSuffix(cert)),
      ),
    ).then(_ =>
      console.info(
        'Outdated certificates removed:',
        outdatedCertificates.map(c => c.friendlyName),
      ),
    );
  };

  render() {
    if (!this.state.isLoaded) {
      return <Loader />;
    }
    return (
      <>
        <BoxBody>
          {this.renderAlerts()}
          <p>
            <FormattedMessage id="certificate.selector.please.select" />
          </p>
          <div
            className="certificate-list-box"
            onDrop={this.handleCertificatesDrop}
            onDragOver={e => e.preventDefault()}
          >
            <div className="list-group list-group-flush">
              {this.state.certificatesFile && (
                <NewCertificateEntryForm
                  filename={this.state.certificatesFile?.name}
                  handleFriendlyNameChange={
                    this.handleNewCertificateFriendlyNameChange
                  }
                  handlePasswordChange={this.handleImportPasswordChange}
                  disablePasswordInput={
                    this.state.hasNewCertificateValidPassword ||
                    !!this.state.certificatesFriendlyNameValidationAlertProps ||
                    this.state.certificatesFile.name
                      .toLowerCase()
                      .endsWith(
                        '.pem',
                      ) /* TODO JWA workaround as long as PEMs are not encrypted */
                  }
                  password={this.state.newCertificateImportPassword}
                  friendlyName={this.state.newCertificateFriendlyName}
                  ref={this.newCertificateFriendlyNameInput}
                />
              )}
              {this.state.certificates.map(certificate => (
                <CertificateEntry
                  key={certificate.serialNumber}
                  certificate={certificate}
                  onSelect={this.onCertificateClick}
                  isSelected={
                    certificate.serialNumber ===
                    this.state.selectedCertificate?.serialNumber
                  }
                  onEnterKeyPress={this.handleCertificateSelectionSubmit}
                />
              ))}
            </div>
          </div>
        </BoxBody>
        <BoxFooter
          doNotMoveChildrenToRight={true}
          noBottomPadding={this.emailOtpVisible}
        >
          <div className="col-auto mr-auto">
            <div className="row align-items-center">
              {!this.state.certificatesFile && (
                <ImportCertificatesButton
                  handleCertificatesImport={this.handleCertificatesImport}
                />
              )}
              <SupportPageButton />
            </div>
          </div>
          {!!this.state.certificatesFile && (
            <XactFooterButton
              id="cancel-certificate-import-button"
              onClick={this.cleanUpNewCertificateState}
              i18nKey="certificate.selector.cancel.button.label"
            />
          )}
          {
            /* TODO JWA temporary as long as PEM is unencrypted*/ (this.state
              .hasNewCertificateValidPassword ||
              this.state.certificatesFile?.name.toLowerCase().endsWith('.pem')) &&
              this.state.newCertificateFriendlyName && (
                <XactFooterButton
                  id="submit-certificate-import-button"
                  onClick={this.importCertificatesFromFile}
                  i18nKey="certificate.selector.import.button.label"
                />
              )
          }
          {!this.state.certificatesFile && !!this.state.selectedCertificate && (
            <XactFooterButton
              id="certificate-selection-next-button"
              onClick={this.handleCertificateSelectionSubmit}
              i18nKey="certificate.selector.next.button.label"
            />
          )}
        </BoxFooter>
        {this.emailOtpVisible && (
          <div className="row justify-content-md-center">
            <a
              className="col-auto p-2 footer-text"
              onClick={() => this.props.handleCertificateSelection()}
            >
              <FormattedMessage id={'certificate.selector.no.certificate.label'} />
            </a>
          </div>
        )}
      </>
    );
  }
}

export default CertificateSelector;
