import React from 'react';
import certificateIcon from '../../images/common/certificate-icon.png';
import selectedCertificateIcon from '../../images/common/certificate-icon-selected.png';
import Certificate from '../../models/Certificate';
import { getDisplayName } from '../../utils/certificates';
import { onEnterKeyPress } from '../../utils/enter-key-listener';
import { DATE_FORMAT, TIME_FORMAT_WITH_TZ } from '../../utils/date-time-format';
import { FormattedMessage } from 'react-intl';

interface CertificateEntryProps {
  onSelect: (certificate: Certificate) => void;
  certificate: Certificate;
  isSelected: boolean;
  onEnterKeyPress: () => void;
}

const CertificateEntry: React.FC<CertificateEntryProps> = (
  props: CertificateEntryProps,
) => {
  const certificate = props.certificate;
  const icon = props.isSelected ? selectedCertificateIcon : certificateIcon;

  const certValidToDate: Date = new Date(props.certificate.validTo);
  const expiryDateValueClass = props.certificate.isExpired
    ? 'danger'
    : props.certificate.willExpireSoon
      ? 'warning'
      : '';

  const getFormattedExpiryDate = function () {
    return (
      <>
        {DATE_FORMAT.format(certValidToDate) + ' '}
        <span className="text-secondary">
          {TIME_FORMAT_WITH_TZ.format(certValidToDate)}
        </span>
      </>
    );
  };

  const renderCertificateDataRow = function (
    labelI18NKey: string,
    value: any,
    valueClass?: string,
  ) {
    return (
      <div className="row m-0">
        <label className="col-4 p-0">
          <FormattedMessage id={labelI18NKey} />
        </label>
        <div className={`col-8 p-0 ${valueClass}`}>{value}</div>
      </div>
    );
  };

  return (
    <button
      onClick={() => props.onSelect(certificate)}
      onKeyPress={event => onEnterKeyPress(event, props.onEnterKeyPress)}
      className={`certificate list-group-item list-group-item-action container border-bottom ${props.isSelected ? 'active' : ''}`}
      autoFocus={props.isSelected}
    >
      <div className="row m-0 align-items-center">
        <div className="col-2 p-0 text-center">
          <img src={icon} alt="certificate" />
        </div>
        <div className="col-10 p-0">
          {renderCertificateDataRow(
            'certificate.friendly.name',
            getDisplayName(certificate),
          )}
          {renderCertificateDataRow(
            'certificate.xact.user.id',
            certificate.subject.CN,
          )}
          {certificate.subjectAlternativeName
            ? renderCertificateDataRow(
                'certificate.clearstream.user.id',
                certificate.subjectAlternativeName,
              )
            : null}
          {renderCertificateDataRow(
            'certificate.expiry.date',
            getFormattedExpiryDate(),
            expiryDateValueClass,
          )}
        </div>
      </div>
    </button>
  );
};

export default CertificateEntry;
