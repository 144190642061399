import React, { ChangeEvent } from 'react';
import BoxFooter from '../common/BoxFooter';
import XactFooterButton from '../common/XactFooterButton';
import Alert, { AlertType } from '../common/Alert';
import OpenAmMetaMessage, {
  OpenAmMetaMessageType,
} from '../../openam/OpenAmMetaMessage';
import BoxBody from '../common/BoxBody';
import { FormattedMessage } from 'react-intl';
import { onEnterKeyPress } from '../../utils/enter-key-listener';
import hasInvalidSymbols from '../../utils/password';

interface PasswordUpdateFormProps {
  messages: Array<OpenAmMetaMessage> | null;
  handlePasswordUpdate: (newPassword: string) => void;
}

interface PasswordUpdateFormState {
  newPassword: string;
  newPasswordConfirm: string;
  passwordsMismatch: boolean;
  passwordsHasInvalidSymbols: boolean;
}

class PasswordUpdateForm extends React.Component<
  PasswordUpdateFormProps,
  PasswordUpdateFormState
> {
  private readonly passwordConfirmRef: React.RefObject<HTMLInputElement>;

  constructor(props: PasswordUpdateFormProps) {
    super(props);
    this.passwordConfirmRef = React.createRef();
    this.state = {
      newPassword: '',
      newPasswordConfirm: '',
      passwordsMismatch: false,
      passwordsHasInvalidSymbols: false,
    };

    this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this);
    this.handleNewPasswordConfirmChange =
      this.handleNewPasswordConfirmChange.bind(this);
    this.checkPasswordsAndSubmit = this.checkPasswordsAndSubmit.bind(this);
  }

  handleNewPasswordChange(event: ChangeEvent<HTMLInputElement>) {
    this.setState({
      newPassword: event.target.value,
      passwordsHasInvalidSymbols: hasInvalidSymbols(event.target.value),
    } as PasswordUpdateFormState);
  }

  handleNewPasswordConfirmChange(event: ChangeEvent<HTMLInputElement>) {
    const newPasswordConfirm = event.target.value;
    this.setState(previousState => {
      return {
        /** if passwordsMismatch == true => check if passwords do match */
        passwordsMismatch:
          previousState.passwordsMismatch &&
          previousState.newPassword !== newPasswordConfirm,
        newPasswordConfirm: newPasswordConfirm,
      } as PasswordUpdateFormState;
    });
  }

  checkPasswordsAndSubmit() {
    this.setState(
      previousState => {
        return {
          passwordsMismatch:
            previousState.newPassword !== previousState.newPasswordConfirm,
        } as PasswordUpdateFormState;
      },
      () => {
        if (
          !this.state.passwordsMismatch &&
          !this.state.passwordsHasInvalidSymbols
        ) {
          this.props.handlePasswordUpdate(this.state.newPassword);
        }
      },
    );
  }

  render() {
    const errorMessages: Array<OpenAmMetaMessage> | undefined =
      this.props.messages?.filter(m => m.type === OpenAmMetaMessageType.ERROR);
    const titleI18nKey: string =
      this.props.messages?.find(m => m.type === OpenAmMetaMessageType.INFO)
        ?.i18nKey || 'password.update.default.title';
    return (
      <>
        <BoxBody>
          {this.state.passwordsMismatch && (
            <Alert type={AlertType.Error} i18nKey="password.mismatch" />
          )}
          {this.state.passwordsHasInvalidSymbols && (
            <Alert type={AlertType.Error} i18nKey="password.with.invalid.symbols" />
          )}
          {errorMessages && errorMessages.length > 0 && (
            /** We show only the 1st message */
            <Alert
              type={AlertType.Error}
              i18nKey={errorMessages[0].i18nKey || errorMessages[0].providedI18N}
            />
          )}
          <h5 className="mb-0">
            <FormattedMessage id={titleI18nKey} />
          </h5>
          <div className="row m-0 pb-1 pt-4">
            <label htmlFor="newPassword" className="col-5 p-0 small">
              <FormattedMessage id="password.update.new.password.label" />
            </label>
            <div className="col-7 p-0">
              <input
                type="password"
                className="input-field w-100"
                id="newPassword"
                autoFocus={true}
                value={this.state.newPassword}
                onChange={this.handleNewPasswordChange}
                onKeyPress={e =>
                  onEnterKeyPress(e, () => this.passwordConfirmRef.current?.focus())
                }
              />
            </div>
          </div>
          <div className="row m-0">
            <label htmlFor="newPasswordConfirm" className="col-5 p-0 small">
              <FormattedMessage id="password.update.new.password.confirm.label" />
            </label>
            <div className="col-7 p-0">
              <input
                type="password"
                className="input-field w-100"
                id="newPasswordConfirm"
                value={this.state.newPasswordConfirm}
                onChange={this.handleNewPasswordConfirmChange}
                ref={this.passwordConfirmRef}
                onKeyPress={e => onEnterKeyPress(e, this.checkPasswordsAndSubmit)}
              />
            </div>
          </div>
        </BoxBody>
        <BoxFooter>
          <XactFooterButton
            id="submit-password-change-button"
            onClick={this.checkPasswordsAndSubmit}
            i18nKey="submit.label"
          />
        </BoxFooter>
      </>
    );
  }
}

export default PasswordUpdateForm;
