import React from 'react';
import { FormattedMessage } from 'react-intl';

interface ChecklistNodeButtonProps {
  onClick: () => {} | void;
  i18nKey: string;
  i18nValues?: {};
}

const ChecklistNodeButton: React.FC<ChecklistNodeButtonProps> = (
  props: ChecklistNodeButtonProps,
) => {
  return (
    <button
      className="xact-button pl-2 pr-2 border border-dark ml-1"
      onClick={props.onClick}
    >
      <FormattedMessage id={props.i18nKey} values={props.i18nValues} />
    </button>
  );
};

export default ChecklistNodeButton;
