import React, { useContext } from 'react';
import BoxHeader from './BoxHeader';
import PartnerContext from '../../partner-context';

interface BoxProps {
  isSupportPage?: boolean;
  children: React.ReactNode;
}

const Box: React.FC<BoxProps> = props => {
  const partner = useContext(PartnerContext);

  return (
    <div className={`box ${partner} ${props.isSupportPage ? 'support' : ''}`}>
      <BoxHeader />
      {props.children}
    </div>
  );
};

export default Box;
