import React, { useContext } from 'react';
import BoxBody from '../components/common/BoxBody';
import Alert, { AlertType } from '../components/common/Alert';
import BoxFooter from '../components/common/BoxFooter';
import XactFooterButton from '../components/common/XactFooterButton';
import { Link } from 'react-router-dom';
import Box from '../components/common/Box';
import PartnerContext, { Partner } from '../partner-context';

const LogoutPage: React.FC = () => {
  const partner = useContext(PartnerContext);
  const isHsbc: boolean = partner === Partner.Hsbc;

  return (
    <Box>
      <BoxBody>
        <Alert type={AlertType.Info} i18nKey="logged.out.from.portal" />
      </BoxBody>
      <BoxFooter>
        {!isHsbc && (
          <Link to="/">
            <XactFooterButton
              id="return-button"
              autoFocus={true}
              i18nKey="support.page.button.return"
            />
          </Link>
        )}
      </BoxFooter>
    </Box>
  );
};

export default LogoutPage;
