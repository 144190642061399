import React from 'react';
import { FormattedMessage } from 'react-intl';
import Alert, { AlertType } from '../common/Alert';
import BoxBody from '../common/BoxBody';
import BoxFooter from '../common/BoxFooter';
import XactFooterButton from '../common/XactFooterButton';
import Certificate from '../../models/Certificate';
import OpenAmMetaMessage, {
  OpenAmMetaMessageType,
} from '../../openam/OpenAmMetaMessage';
import PartnerContext from '../../partner-context';

interface CertificatesUpdateFormProps {
  selectedCertificate: Certificate;
  handleCertificateUpdate: () => void;
  messages: Array<OpenAmMetaMessage> | null | undefined;
}

interface CertificatesUpdateFormState {
  errorMessages?: Array<OpenAmMetaMessage> | null;
  titleKey: string;
}

class CertificatesUpdateForm extends React.Component<
  CertificatesUpdateFormProps,
  CertificatesUpdateFormState
> {
  constructor(props: CertificatesUpdateFormProps) {
    super(props);
    const titleI18nKey =
      this.props.messages?.find(m => m.type === OpenAmMetaMessageType.INFO)
        ?.i18nKey || 'certificates.update.default.title';
    // Only one (Software) certificate will be allowed
    this.state = {
      errorMessages: this.props.messages?.filter(
        m => m.type === OpenAmMetaMessageType.ERROR,
      ),
      titleKey: titleI18nKey,
    };
  }

  private submitForm = () => {
    this.props.handleCertificateUpdate();
  };

  render() {
    return (
      <>
        <BoxBody>
          {this.state.errorMessages?.map(message => (
            <Alert
              type={AlertType.Error}
              i18nKey={message.i18nKey || message.providedI18N}
            />
          ))}
          <h5>
            <FormattedMessage id={this.state.titleKey} />
          </h5>
        </BoxBody>
        <BoxFooter>
          <XactFooterButton
            id="submit-certificates-updates-button"
            onClick={this.submitForm}
            i18nKey="certificates.update.submit.label"
          />
        </BoxFooter>
      </>
    );
  }
}

CertificatesUpdateForm.contextType = PartnerContext;

export default CertificatesUpdateForm;
