import IndexedDbCertificateEntry from '../models/IndexedDbCertificateEntry';
import Certificate from '../models/Certificate';

export const MAX_FRIENDLY_NAME_LENGTH: number = 40;

/** Ten days is what is hardcoded into our OpenAM check to warn the user about his certificate expiration */
const TEN_DAYS = 1000 * 60 * 60 * 24 * 10;

export const setCertificateIsExpiredAndWillExpireSoon = (
  certificate: Certificate,
) => {
  const certValidToDate: Date = new Date(certificate.validTo);
  const now: Date = new Date();

  const isCertificateExpired = certValidToDate <= now;
  const willCertificateExpireSoon =
    !isCertificateExpired && certValidToDate.getTime() - now.getTime() < TEN_DAYS;

  certificate.isExpired = isCertificateExpired;
  certificate.willExpireSoon = willCertificateExpireSoon;
};

export const SIGN_CERT_SUFFIX: string = '-SIGN';
const SSL_CERT_SUFFIX: string = '-SSL';
const CACERT_CERT_SUFFIX: string = '-CACERT';
const BACKUP_CERT_SUFFIX: string = '-BACKUP';
const SSL_BACKUP_CERT_SUFFIX: string = '-SSL-BACKUP';
export const SIGN_BACKUP_CERT_SUFFIX: string = '-SIGN-BACKUP';

export const CERTIFICATE_SUFFIXES: Array<string> = [
  SIGN_CERT_SUFFIX,
  SSL_CERT_SUFFIX,
  CACERT_CERT_SUFFIX,
  SIGN_BACKUP_CERT_SUFFIX,
  SSL_BACKUP_CERT_SUFFIX,
];

export const CERTIFICATE_SUFFIX_KEYWORDS: Array<string> = [
  SIGN_CERT_SUFFIX,
  SSL_CERT_SUFFIX,
  CACERT_CERT_SUFFIX,
  BACKUP_CERT_SUFFIX,
];

export const removeSuffix = (friendlyName: string): string =>
  friendlyName
    .replace(SIGN_CERT_SUFFIX, '')
    .replace(SSL_CERT_SUFFIX, '')
    .replace(CACERT_CERT_SUFFIX, '')
    .replace(BACKUP_CERT_SUFFIX, '');

export const getFriendlyNameWithoutSuffix = (
  certificate: Certificate | IndexedDbCertificateEntry,
): string => removeSuffix(certificate.friendlyName);

export const getDisplayName = (
  certificate: Certificate | IndexedDbCertificateEntry,
): string => decodeFriendlyName(getFriendlyNameWithoutSuffix(certificate));

export const isSignCertificate = (
  certificate: Certificate | IndexedDbCertificateEntry,
): boolean => certificate.friendlyName.endsWith(SIGN_CERT_SUFFIX);

export const isSslCertificate = (
  certificate: Certificate | IndexedDbCertificateEntry,
): boolean => certificate.friendlyName.endsWith(SSL_CERT_SUFFIX);

export const isPlaceholder = (indexedDbEntry: IndexedDbCertificateEntry): boolean =>
  indexedDbEntry.certificate === 'placeholder';

export const hasPlaceholderSignCertificate = (
  indexedDbEntry: IndexedDbCertificateEntry,
): boolean => isSignCertificate(indexedDbEntry) && isPlaceholder(indexedDbEntry);

/** A regular expression which accepts Characters, Numbers, Dashes, Underscores & Spaces */
const FRIENDLY_NAME_REGEX = /^[a-zA-Z0-9_ -]*$/;

export const friendlyNameContainsOnlyAllowedSymbols = (
  friendlyName: string,
): boolean => FRIENDLY_NAME_REGEX.test(friendlyName);

export const friendlyNameContainsKeywords = (friendlyName: string): boolean =>
  CERTIFICATE_SUFFIX_KEYWORDS.some(suffix => friendlyName.includes(suffix));

export const friendlyNameIsTooLong = (friendlyName: string): boolean =>
  friendlyName.length > MAX_FRIENDLY_NAME_LENGTH;

/** In the IndexedDB we should not have spaces in the name, so behind the scenes we replace them with dots. */
export const encodeFriendlyName = (friendlyName: string): string =>
  friendlyName?.replace(/ /g, '.');
export const decodeFriendlyName = (friendlyName: string): string =>
  friendlyName?.replace(/\./g, ' ');

const groupByUserId = (
  certificates: Array<Certificate>,
): Map<string, Array<Certificate>> => {
  return certificates.reduce(
    (certMap: Map<string, Array<Certificate>>, certificate: Certificate) => {
      const userId: string = certificate.subject.CN;
      const userCertificates = certMap.get(userId) || [];
      return certMap.set(userId, userCertificates.concat(certificate));
    },
    new Map<string, Array<Certificate>>(),
  );
};

const compareCertificateValidFrom = (a: Certificate, b: Certificate): number =>
  new Date(a.validFrom).getTime() - new Date(b.validFrom).getTime();

/**
 * Search in given certificates for ones that have been regenerated and can not be used for login anymore.
 * @param certificates
 * @return outdated/superseded certificates
 */
export const getOutdatedCertificates = (
  certificates: Array<Certificate>,
): Array<Certificate> => {
  const groupedByUser: Map<string, Array<Certificate>> = groupByUserId(certificates);
  const result: Array<Certificate> = [];
  groupedByUser.forEach((userCertificates: Array<Certificate>) =>
    result.push(...userCertificates.sort(compareCertificateValidFrom).slice(0, -1)),
  );
  return result;
};
