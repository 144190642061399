import React from 'react';
import { FormattedMessage } from 'react-intl';

export enum AlertType {
  Info = 'alert-primary',
  Warning = 'alert-warning',
  Error = 'alert-danger',
  Success = 'alert-success',
}

export interface AlertProps {
  type: AlertType;
  i18nKey: string;
  i18nValues?: any;
}

const Alert: React.FC<AlertProps> = (props: AlertProps) => {
  return (
    <div className={`alert ${props.type} line-break`} role="alert">
      <FormattedMessage id={props.i18nKey || ' '} values={props.i18nValues} />
    </div>
  );
};

export default Alert;
