import React, { useCallback, useEffect, useState } from 'react';
import Certificate from '../../models/Certificate';
import {
  getIndexedDbCertificates,
  removeCertificates,
  renameCertificates,
} from '../../utils/reactWebCryptoAdapter';
import {
  decodeFriendlyName,
  encodeFriendlyName,
  friendlyNameContainsKeywords,
  friendlyNameContainsOnlyAllowedSymbols,
  friendlyNameIsTooLong,
  getFriendlyNameWithoutSuffix,
} from '../../utils/certificates';
import { FormattedMessage, useIntl } from 'react-intl';
import CertificatesGroup from './CertificatesGroup';
import Loader from '../common/Loader';
import GroupedCertificates from '../../models/GroupedCertificates';
import Alert, { AlertType } from '../common/Alert';

interface ManageCertificatesState {
  indexedDbCertificates: Array<Certificate>;
  isLoaded: boolean;
  error?: string;
}

const ManageCertificates: React.FC = () => {
  const [certState, setCertState] = useState<ManageCertificatesState>({
    indexedDbCertificates: [],
    isLoaded: false,
    error: '',
  });

  const intl = useIntl();

  const getI18n = useCallback(
    (id: string, values?: any) => intl.formatMessage({ id: id }, values),
    [intl],
  );

  useEffect(() => {
    const loadCertificates = () => {
      setCertState(prev => {
        return {
          ...prev,
          ...{ isLoaded: false },
        };
      });
      // Wait only for reject or resolve of fastest promise
      Promise.race([loadIndexDbCertificates()]).finally(() =>
        setCertState(prev => {
          return {
            ...prev,
            ...{ isLoaded: true },
          };
        }),
      );
    };

    loadCertificates();
  }, []);

  const reloadCertificates = () => {
    setCertState(prev => {
      return {
        ...prev,
        ...{ isLoaded: false },
      };
    });
    loadIndexDbCertificates().finally(() =>
      setCertState(prev => {
        return {
          ...prev,
          ...{ isLoaded: true },
        };
      }),
    );
  };

  const loadIndexDbCertificates = () => {
    return getIndexedDbCertificates()
      .then(certificates =>
        setCertState(prev => {
          return {
            ...prev,
            ...{ indexedDbCertificates: certificates },
          };
        }),
      )
      .catch(reason =>
        setCertState(prev => {
          return {
            ...prev,
            ...{ error: reason?.message || reason },
          };
        }),
      );
  };

  const groupCertificates = (): Array<GroupedCertificates> => {
    const allCertificates: Array<Certificate> = certState.indexedDbCertificates;
    return allCertificates.reduce(
      (certificatesGroups: Array<GroupedCertificates>, certificate: Certificate) => {
        const friendlyNameWithoutSuffix = getFriendlyNameWithoutSuffix(certificate);
        const group = certificatesGroups.find(
          g => g.friendlyName === friendlyNameWithoutSuffix,
        );
        if (!group) {
          certificatesGroups.push({
            friendlyName: friendlyNameWithoutSuffix,
            certificates: [certificate],
          });
        } else {
          group.certificates.push(certificate);
        }
        return certificatesGroups;
      },
      [],
    );
  };

  const renameCertificatesCall = (group: GroupedCertificates) => {
    const displayName = decodeFriendlyName(group.friendlyName);
    const message = getI18n('support.page.manage.certificates.rename.prompt', {
      friendlyName: displayName,
    });
    let newFriendlyNameWithoutSuffix = prompt(message, displayName);
    if (newFriendlyNameWithoutSuffix === null) {
      return;
    }
    if (
      !newFriendlyNameWithoutSuffix ||
      !friendlyNameContainsOnlyAllowedSymbols(newFriendlyNameWithoutSuffix) ||
      friendlyNameIsTooLong(newFriendlyNameWithoutSuffix) ||
      friendlyNameContainsKeywords(newFriendlyNameWithoutSuffix)
    ) {
      alert(getI18n('support.page.manage.certificates.rename.constraints'));
      return;
    }
    setCertState(prev => {
      return {
        ...prev,
        ...{ isLoaded: false },
      };
    });
    newFriendlyNameWithoutSuffix = encodeFriendlyName(newFriendlyNameWithoutSuffix);
    renameCertificates(group.friendlyName, newFriendlyNameWithoutSuffix).then(() =>
      reloadCertificates(),
    );
  };

  const removeCertificatesCall = (group: GroupedCertificates) => {
    const displayName = decodeFriendlyName(group.friendlyName);
    const message = getI18n('support.page.manage.certificates.delete.warning', {
      friendlyName: displayName,
    });
    if (window.confirm(message)) {
      setCertState(prev => {
        return {
          ...prev,
          ...{ isLoaded: false },
        };
      });
      removeCertificates(group.friendlyName).then(() => reloadCertificates());
    }
  };

  if (!certState.isLoaded) {
    return <Loader />;
  }

  const certificatesGroups = groupCertificates().map(group => (
    <CertificatesGroup
      certificatesGroup={group}
      renameCertificates={renameCertificatesCall}
      removeCertificates={removeCertificatesCall}
    />
  ));

  return (
    <>
      {certState.error && <Alert type={AlertType.Error} i18nKey={certState.error} />}
      {certificatesGroups.length < 1 ? (
        <FormattedMessage id="support.page.manage.certificates.none.found" />
      ) : (
        certificatesGroups
      )}
    </>
  );
};

export default ManageCertificates;
