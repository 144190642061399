import React, { ChangeEvent, useContext, useState } from 'react';
import '../../css/components/PasswordForm.css';
import BoxFooter from '../common/BoxFooter';
import XactFooterButton from '../common/XactFooterButton';
import BoxBody from '../common/BoxBody';
import { onEnterKeyPress } from '../../utils/enter-key-listener';
import PartnerContext from '../../partner-context';
import UserIcon from '../common/UserIcon';
import PasswordIcon from '../common/PasswordIcon';
import { FormattedMessage, useIntl } from 'react-intl';
import Alert, { AlertType } from '../common/Alert';

interface PasswordFormProps {
  commonName?: string;
  subjectAlternativeName?: string;
  handlePasswordSubmit: (password: string, cn: string, san?: string) => void;
}

const PasswordForm: React.FC<PasswordFormProps> = props => {
  const cnPattern = new RegExp('^[a-zA-Z0-9_]+$');
  const sanPattern = new RegExp('^[a-zA-Z0-9]{5}$');
  const partner = useContext(PartnerContext);
  const intl = useIntl();

  const [invalidUserIdInput, setInvalidUserIdInput] = useState(false);
  const [invalidPasswordIdInput, setInvalidPasswordIdInput] = useState(false);
  const [password, setPassword] = useState('');
  const [cn, setCn] = useState(props.commonName || '');
  const [san, setSan] = useState(props.subjectAlternativeName || '');
  const enableUserIdInput = !props.commonName;
  const userIdInputClass = enableUserIdInput
    ? 'input-field'
    : 'input-field-readonly';

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleCnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCn(event.target.value);
  };

  const handleSanChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSan(event.target.value);
  };

  const validateInput = (): boolean => {
    const isPasswordInvalid: boolean = !password;
    setInvalidPasswordIdInput(isPasswordInvalid);
    const isCnInvalid: boolean = !cnPattern.test(cn);
    const isSanInvalid: boolean = !!san && !sanPattern.test(san);
    setInvalidUserIdInput(isCnInvalid || isSanInvalid);
    return !isPasswordInvalid && !isCnInvalid && !isSanInvalid;
  };

  const submitPasswordForm = () => {
    if (validateInput()) {
      props.handlePasswordSubmit(password, cn, san);
    }
  };

  const handlePasswordInputKeyPress = (event: any) =>
    onEnterKeyPress(event, submitPasswordForm);

  return (
    <>
      <BoxBody>
        <div className="container pt-2">
          {invalidPasswordIdInput && (
            <Alert type={AlertType.Error} i18nKey="Invalid password input" />
          )}
          {invalidUserIdInput && (
            <Alert type={AlertType.Error} i18nKey="Invalid user ID input" />
          )}
          <p>
            <FormattedMessage
              id={'password.form.label' + (enableUserIdInput ? '.internal' : '')}
            />
          </p>
          <div className="row">
            <UserIcon />
            {(enableUserIdInput || props.subjectAlternativeName) && (
              <input
                readOnly={!enableUserIdInput}
                onChange={handleSanChange}
                className={`col-3 m-1 ${userIdInputClass} ${partner}`}
                type="text"
                placeholder="ab123"
                autoFocus={enableUserIdInput}
                value={`${san}`}
              />
            )}
            <input
              className={`col m-1 ${userIdInputClass} ${partner}`}
              onChange={handleCnChange}
              readOnly={!enableUserIdInput}
              type="text"
              placeholder="a12345678_1234567"
              value={`${cn}`}
            />
          </div>
          <div className="row">
            <PasswordIcon />
            <input
              id="login-password-field"
              className="col m-1 input-field"
              onChange={handlePasswordChange}
              autoFocus={!enableUserIdInput}
              onKeyPress={handlePasswordInputKeyPress}
              type="password"
              placeholder={intl.formatMessage({ id: 'password.placeholder' })}
            />
          </div>
        </div>
      </BoxBody>
      <BoxFooter>
        <XactFooterButton
          id="submit-login-password"
          onClick={submitPasswordForm}
          i18nKey="password.form.login.button"
          disabled={!password}
        />
      </BoxFooter>
    </>
  );
};

export default PasswordForm;
