import React from 'react';

interface BoxFooterProps {
  doNotMoveChildrenToRight?: boolean;
  noBottomPadding?: boolean;
  children: React.ReactNode;
}

const BoxFooter: React.FC<BoxFooterProps> = props => {
  return (
    <div
      className={`row m-0 align-items-center footer ${props.noBottomPadding ? 'no-bottom-padding' : ''}`}
    >
      {!props.doNotMoveChildrenToRight && <div className="flex-grow-1 hidden" />}
      {props.children}
    </div>
  );
};

export default BoxFooter;
