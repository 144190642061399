import React, { useState } from 'react';
import BoxFooter from '../common/BoxFooter';
import XactFooterButton from '../common/XactFooterButton';
import BoxBody from '../common/BoxBody';
import { FormattedMessage } from 'react-intl';
import { onEnterKeyPress } from '../../utils/enter-key-listener';

interface EmailOtpVerifierProps {
  handleEmailOtp: (otp: string) => void;
}

function EmailOtpVerifier(props: EmailOtpVerifierProps) {
  const [opt, setOpt] = useState('');
  const isInvalidOtp = !opt || opt.length < 8;

  return (
    <>
      <BoxBody>
        <p>
          <FormattedMessage id="email.otp.input" />
        </p>
        <input
          type="text"
          id="oauth-token"
          className="input-field"
          onChange={e => setOpt(e.target.value)}
          onKeyUp={e =>
            onEnterKeyPress(e, () => {
              if (!isInvalidOtp) {
                props.handleEmailOtp(opt);
              }
            })
          }
          autoFocus={true}
        />
      </BoxBody>
      <BoxFooter>
        <XactFooterButton
          id="submit"
          onClick={() => props.handleEmailOtp(opt)}
          i18nKey="submit.label"
          disabled={isInvalidOtp}
        />
      </BoxFooter>
    </>
  );
}

export default EmailOtpVerifier;
