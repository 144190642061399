import React, { useRef } from 'react';
import XactButton from '../common/XactButton';
import { download } from '../../utils/download';

const ChecklistDownloadReportButton: React.FC = () => {
  /** Form security-fe/lib/webcrypto/security/src/Utils.js:2 */
  const DEFAULT_DISPLAY = 'output';
  const outputRef: React.RefObject<HTMLSpanElement> = useRef(null);

  const downloadLogHistory = () => {
    const log: string = outputRef.current?.innerHTML || '';
    download(log, 'checklist-report.html', 'text/html');
  };

  return (
    <>
      <XactButton
        id="downloadReport"
        i18nKey="support.page.checks.download.report"
        onClick={downloadLogHistory}
        addBorder={true}
      />
      <span id={DEFAULT_DISPLAY} hidden={true} ref={outputRef} />
    </>
  );
};

export default ChecklistDownloadReportButton;
