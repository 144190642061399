import React from 'react';

interface BoxProps {
  children: React.ReactNode;
}

const BoxBody: React.FC<BoxProps> = props => {
  return <div className="content d-flex flex-column">{props.children}</div>;
};

export default BoxBody;
