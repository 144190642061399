import React from 'react';
import BoxBody from '../common/BoxBody';
import BoxFooter from '../common/BoxFooter';
import XactFooterButton from '../common/XactFooterButton';
import QRCode from 'react-qr-code';
import { FormattedMessage } from 'react-intl';
import GetAuthenticatorAppButton from './GetAuthenticatorAppButton';

interface OathRegistrationProps {
  message?: string;
  mfaDeviceRegistration?: string;
  handleOathRegistration: () => void;
}

function OathRegistration(props: OathRegistrationProps) {
  const code = 'M';
  const qrValue = props.message || '';
  return (
    <>
      <BoxBody>
        <label className="m-0" htmlFor="resource">
          <FormattedMessage id="scan.qr.code" />
        </label>
        <div className="d-flex h-100 w-100 justify-content-center align-items-center">
          <QRCode level={code} value={qrValue} size={200} />
        </div>
      </BoxBody>
      <BoxFooter doNotMoveChildrenToRight={true}>
        <div className="col-auto mr-auto">
          <GetAuthenticatorAppButton />
        </div>
        <XactFooterButton
          id="confirm"
          onClick={props.handleOathRegistration}
          i18nKey="scan.qr.code.confirm"
        />
      </BoxFooter>
    </>
  );
}

export default OathRegistration;
