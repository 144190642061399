import Certificate from '../models/Certificate';
import IndexedDbCertificateEntry from '../models/IndexedDbCertificateEntry';
import CertificateSigningRequests from '../models/CertificateSigningRequests';
import {
  CERTIFICATE_SUFFIXES,
  getFriendlyNameWithoutSuffix,
  hasPlaceholderSignCertificate,
  isPlaceholder,
  isSignCertificate,
  removeSuffix,
  setCertificateIsExpiredAndWillExpireSoon,
  SIGN_BACKUP_CERT_SUFFIX,
} from './certificates';
import { getWebCryptoSecurity } from './global-variables';

/**
 * WebCrypto adapter.
 *
 * Wraps WebCrypto libraries in order to hide complexity of certificates related operations
 * from React components.
 *
 * The file:
 * - uses Certificate and other interfaces used in React components in parameters and as output;
 * - does steps needed before WebCrypto calls;
 * - wraps callback chains in promises.
 *
 */

export const initialize = (): Promise<any> => {
  const webCryptoSecurity = getWebCryptoSecurity();
  if (!webCryptoSecurity) {
    throw new Error('webcrypto.initialize.timeout');
  }
  return webCryptoSecurity.initialize();
};

export async function getIndexedDbCertificates(
  onlySigning?: boolean,
): Promise<Array<Certificate>> {
  const webCryptoSecurity = getWebCryptoSecurity();
  let indexedDbEntries: Array<IndexedDbCertificateEntry> =
    await webCryptoSecurity.getCertificates();
  if (
    indexedDbEntries.some(indexedDbEntry =>
      hasPlaceholderSignCertificate(indexedDbEntry),
    )
  ) {
    await restoreBackupCertificates(indexedDbEntries);
    indexedDbEntries = await webCryptoSecurity.getCertificates();
  }
  if (onlySigning) {
    // In case restoreBackupCertificates failed, do not show placeholder certificates
    indexedDbEntries = indexedDbEntries.filter(
      e => isSignCertificate(e) && !isPlaceholder(e),
    );
  }
  const certificates: Array<Certificate> = indexedDbEntries.map(indexedDbEntry => {
    const certificate = indexedDbEntryToCertificate(indexedDbEntry);
    certificate.isPrivateKeyExtractable = indexedDbEntry.isPrivateKeyExtractable;
    setCertificateIsExpiredAndWillExpireSoon(certificate);
    return certificate;
  });
  return certificates;
}

function indexedDbEntryToCertificate(
  indexedDbEntry: IndexedDbCertificateEntry,
): Certificate {
  const webCryptoSecurity = getWebCryptoSecurity();
  // In case restoreBackupCertificates failed, show available certificate data
  const parsedData = isPlaceholder(indexedDbEntry)
    ? {}
    : webCryptoSecurity.getParsedCertificateInformationFromByteArray(
        webCryptoSecurity.stringToArrayBuffer(
          webCryptoSecurity.fromBase64(indexedDbEntry.certificate),
        ),
      );
  return {
    friendlyName: indexedDbEntry.friendlyName,
    lastUsed: indexedDbEntry.lastUsed,
    lastModified: indexedDbEntry.lastModified,
    createdOn: indexedDbEntry.createdOn,
    ...parsedData,
  };
}

async function restoreBackupCertificates(
  indexedDbEntries: Array<IndexedDbCertificateEntry>,
) {
  const webCryptoSecurity = getWebCryptoSecurity();
  const signIndexedDbEntriesWithPlaceholder = indexedDbEntries.filter(
    signIndexedDbEntry => hasPlaceholderSignCertificate(signIndexedDbEntry),
  );

  const friendlyNamesOfAllIndexedDbEntries = indexedDbEntries
    .filter(indexedDbEntry => !isPlaceholder(indexedDbEntry))
    .map(indexedDbEntry => indexedDbEntry.friendlyName);

  const friendlyNamesWithoutSuffix = signIndexedDbEntriesWithPlaceholder.map(
    indexedDbEntry => getFriendlyNameWithoutSuffix(indexedDbEntry),
  );

  for (const friendlyNameWithoutSuffix of friendlyNamesWithoutSuffix) {
    const signBackupFriendlyName =
      friendlyNameWithoutSuffix + SIGN_BACKUP_CERT_SUFFIX;
    if (friendlyNamesOfAllIndexedDbEntries.includes(signBackupFriendlyName)) {
      await webCryptoSecurity.restoreCertificatesFromBackups(
        friendlyNameWithoutSuffix,
      );
    } else {
      await webCryptoSecurity.deleteCertificates(friendlyNameWithoutSuffix);
    }
  }
}

export function unsetActiveCertificate(): Promise<any> {
  return updateActiveCertificate(undefined);
}

export function setActiveCertificate(certificate: Certificate): Promise<any> {
  return updateActiveCertificate(certificate.friendlyName);
}

const updateActiveCertificate = (friendlyName?: string): Promise<any> =>
  getWebCryptoSecurity().setActiveCertificate(friendlyName, false);

export async function sign(certificate: Certificate, data: string): Promise<string> {
  const cert = await getWebCryptoSecurity().getCertificate(certificate.friendlyName);
  if (!cert) {
    // TODO Liubov: add to i18n
    throw `User ${removeSuffix(certificate.friendlyName)} certificate was not found in the browser.`;
  }
  return await getWebCryptoSecurity().signData(certificate.friendlyName, data);
}

export async function generateWebCryptoCSRs(
  oldSigningCertificate: Certificate,
): Promise<CertificateSigningRequests> {
  const certificates = await getWebCryptoSecurity().createSslAndSignCSRs(
    oldSigningCertificate.friendlyName,
    oldSigningCertificate.subject.CN,
    oldSigningCertificate.subjectAlternativeName,
  );
  return { sslCSR: certificates[0], signCSR: certificates[1] };
}

export async function updateWebCryptoCertificates(
  friendlyName: string,
  sslCertificate: string,
  signCertificate: string,
  caCertificate: string,
  newP12BackupPassword: string,
) {
  const friendlyNameWithoutSuffix = removeSuffix(friendlyName);
  // Overwrite placeholder entries in the IndexedDB (which contain the private keys) with the newly returned certificates
  const webCryptoSecurity = getWebCryptoSecurity();
  await webCryptoSecurity.updateCertificatePlaceholder(
    friendlyNameWithoutSuffix + '-SSL',
    sslCertificate,
  );
  await webCryptoSecurity.updateCertificatePlaceholder(
    friendlyNameWithoutSuffix + '-SIGN',
    signCertificate,
  );
  await webCryptoSecurity.updateCertificatePlaceholder(
    friendlyNameWithoutSuffix + '-CACERT',
    caCertificate,
  );

  // Create a backup P12 and launch the automated download of this file
  await webCryptoSecurity.createOpenSSLLikeP12(
    friendlyNameWithoutSuffix,
    newP12BackupPassword,
  );
  await updateActiveCertificate(friendlyName);
}

export function renameCertificates(
  oldFriendlyNameWithoutSuffix: string,
  newFriendlyNameWithoutSuffix: string,
): Promise<void> {
  return renameIndexDbCertificates(
    oldFriendlyNameWithoutSuffix,
    newFriendlyNameWithoutSuffix,
  );
}

const copyCertificates = (
  oldFriendlyNameWithoutSuffix: string,
  newFriendlyNameWithoutSuffix: string,
) => {
  return Promise.all(
    CERTIFICATE_SUFFIXES.map(suffix =>
      getWebCryptoSecurity().copyCertificateEntry(
        oldFriendlyNameWithoutSuffix + suffix,
        newFriendlyNameWithoutSuffix + suffix,
      ),
    ),
  );
};

async function renameIndexDbCertificates(
  oldFriendlyNameWithoutSuffix: string,
  newFriendlyNameWithoutSuffix: string,
): Promise<void> {
  await copyCertificates(oldFriendlyNameWithoutSuffix, newFriendlyNameWithoutSuffix);
  await getWebCryptoSecurity().deleteCertificates(oldFriendlyNameWithoutSuffix);
}

export async function removeCertificates(
  friendlyNameWithoutSuffix: string,
): Promise<void> {
  return getWebCryptoSecurity().deleteCertificates(friendlyNameWithoutSuffix);
}

export const getRandomString = (): string =>
  getWebCryptoSecurity().getRandomString();
