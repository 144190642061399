import React, { useContext } from 'react';
import clearstream_logo_portal from '../../images/clearstream/clearstream_logo_portal.png';
import cetip_logo_portal from '../../images/cetip/cetip_logo_portal.png';
import asx_logo_portal from '../../images/asx/asx_logo_portal.png';
import hqla_logo_portal from '../../images/hqla/hqla_logo_portal.png';
import hsbc_logo_portal from '../../images/hsbc/hsbc_logo_portal.png';
import tmx_logo_portal from '../../images/tmx/tmx_logo_portal.png';
import strate_logo_portal from '../../images/strate/strate_logo_portal.png';
import cfcl_logo_portal from '../../images/cfcl/cfcl_logo_portal.png';
import northerntrust_logo_portal from '../../images/northerntrust/northerntrust_logo_portal.png';
import PartnerContext from '../../partner-context';
import { FormattedMessage } from 'react-intl';

const logos: { [key: string]: string } = {
  clearstream: clearstream_logo_portal,
  cetip: cetip_logo_portal,
  asx: asx_logo_portal,
  hqla: hqla_logo_portal,
  hsbc: hsbc_logo_portal,
  tmx: tmx_logo_portal,
  cfcl: cfcl_logo_portal,
  strate: strate_logo_portal,
  northerntrust: northerntrust_logo_portal,
};

const BoxHeader: React.FC = () => {
  const partner = useContext(PartnerContext);

  return (
    <div className="header">
      <div>
        <FormattedMessage id="welcome.to" />
      </div>
      <img src={logos[partner]} alt={partner + ' Logo'} />
    </div>
  );
};

export default BoxHeader;
