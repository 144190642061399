export const webCryptoVersion = process.env.REACT_APP_PROJECT_VERSION;

export const loadWebCrypto = () => {
  const loadScript = url => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = url;
      script.type = 'text/javascript';
      script.onload = () => {
        resolve();
      };
      script.onerror = () => reject('Can not load security libraries');
      document.head.appendChild(script);
    });
  };

  // Standard WebCrypto
  return loadScript(`/lib/portal-webcrypto-security-${webCryptoVersion}.js`);
};
