import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import PartnerContext from '../../partner-context';

interface ImportCertificatesButtonProps {
  handleCertificatesImport: (event: any) => void;
}

const ImportCertificatesButton: React.FC<ImportCertificatesButtonProps> = (
  props: ImportCertificatesButtonProps,
) => {
  const fullWidthPlus = '\uFF0B';
  const partner = useContext(PartnerContext);
  const intl = useIntl();

  return (
    <>
      <input
        className="d-none"
        onChange={props.handleCertificatesImport}
        id="certificatesInput"
        type="file"
        accept=".p12,.pem"
      />
      <label
        htmlFor="certificatesInput"
        className={`xact-button ${partner} round-button mr-1`}
        title={intl.formatMessage({ id: 'certificates.import.tooltip' })}
      >
        {fullWidthPlus}
      </label>
    </>
  );
};

export default ImportCertificatesButton;
