/**
 * In the entry point (App.tsx) we are waiting for webCryptoSecurity lib to be loaded
 */
export const getWebCryptoSecurity = () => {
  // window.webCryptoSecurity set by library
  // @ts-ignore
  const webCryptoSecurity = window.webCryptoSecurity;
  if (!webCryptoSecurity) {
    throw new Error('webcrypto.init.not.available');
  }
  return webCryptoSecurity;
};
