import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import PartnerContext from '../../partner-context';

export interface XactButtonProps {
  id: string;
  onClick?: () => void;
  i18nKey: string;
  autoFocus?: boolean;
  disabled?: boolean;
  addBorder?: boolean;
}

const XactButton = React.forwardRef<HTMLButtonElement, XactButtonProps>(
  (props: XactButtonProps, ref) => {
    const partner = useContext(PartnerContext);
    return (
      <button
        id={props.id}
        className={
          `xact-button ${partner} pl-2 pr-2` +
          (props.disabled ? ' disabled' : '') +
          (props?.addBorder ? ' border border-dark' : '')
        }
        onClick={props.onClick}
        autoFocus={props.autoFocus}
        disabled={props.disabled}
        ref={ref}
      >
        {/* If i18n key is not provided show label. @see https://github.com/formatjs/react-intl/blob/master/docs/API.md#message-formatting-fallbacks */}
        <FormattedMessage id={props.i18nKey} />
      </button>
    );
  },
);

export default XactButton;
