export default interface OpenAmMetaMessage {
  type: OpenAmMetaMessageType;
  technicalMessage: boolean;
  i18nKey: string;
  description: string;
  providedI18N: string;
}

export enum OpenAmMetaMessageType {
  DEBUG = 'DEBUG',
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}
