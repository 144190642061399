import React, { useState } from 'react';
import BoxFooter from '../common/BoxFooter';
import XactFooterButton from '../common/XactFooterButton';
import BoxBody from '../common/BoxBody';
import { FormattedMessage } from 'react-intl';
import { onEnterKeyPress } from '../../utils/enter-key-listener';

interface OathTokenVerifierProps {
  handleOathToken: (token: string) => void;
}

function OathTokenVerifier(props: OathTokenVerifierProps) {
  const [token, setToken] = useState('');
  const isTotpTooShort = !token || token.length < 6;

  return (
    <>
      <BoxBody>
        <p>
          <FormattedMessage id="oauth.token.input" />
        </p>
        <input
          type="text"
          id="oauth-token"
          className="input-field"
          onChange={e => setToken(e.target.value)}
          onKeyPress={e => onEnterKeyPress(e, () => props.handleOathToken(token))}
          autoFocus={true}
        />
      </BoxBody>
      <BoxFooter>
        <XactFooterButton
          id="submit"
          onClick={() => props.handleOathToken(token)}
          i18nKey="submit.label"
          disabled={isTotpTooShort}
        />
      </BoxFooter>
    </>
  );
}

export default OathTokenVerifier;
