import React from 'react';
import SupportPageCheck, {
  SupportPageCheckResult,
} from '../../models/SupportPageCheck';
import IndexedDbCertificateEntry from '../../models/IndexedDbCertificateEntry';
import ChecklistNode from './ChecklistNode';
import { webCryptoVersion } from '../../utils/portal-webcrypto-loader';
import { TroubleshootingKey } from '../../models/TroubleshootingKey';
import { FormattedMessage } from 'react-intl';
import { isChrome, isFirefox } from '../../utils/browser-check';
import { getWebCryptoSecurity } from '../../utils/global-variables';
import ChecklistDownloadReportButton from './ChecklistDownloadReportButton';

interface ChecklistProps {
  onMoreInformationClicked: (anchor: TroubleshootingKey) => void;
}

interface ChecklistState {
  checklistChecks: Array<SupportPageCheck>;
}

class Checklist extends React.Component<ChecklistProps, ChecklistState> {
  constructor(props: ChecklistProps) {
    super(props);
    this.state = {
      checklistChecks: [],
    };
  }

  componentDidMount() {
    this.runChecks();
  }

  runChecks = async () => {
    this.runBrowserDetectionCheck();
    this.runNativePromiseSupportCheck();
    await this.runWebCryptoSupportChecks();
    /** We need to runIndexedDbChecks first to init WebCrypto */
    await this.runIndexedDbChecks();
    await this.runSecurityFeatureChecks();
  };

  updateCheckInChecklistState = (check: SupportPageCheck) => {
    this.setState(state => {
      const checklistChecks: Array<SupportPageCheck> = state.checklistChecks.filter(
        c => c.id !== check.id,
      );
      checklistChecks.push(check);
      return { checklistChecks: checklistChecks.sort((a, b) => a.id - b.id) };
    });
  };

  runBrowserDetectionCheck = () => {
    const browserDetectionCheck: SupportPageCheck = {
      id: 1,
      labelI18nKey: 'support.page.checks.browser.detection',
      result: SupportPageCheckResult.OK,
    };
    if (!isChrome && !isFirefox) {
      browserDetectionCheck.result = SupportPageCheckResult.INFO;
      browserDetectionCheck.resolutionHintI18nKey =
        'support.page.checks.resolution.recommend.chrome.firefox';
      browserDetectionCheck.troubleShootingAnchor =
        TroubleshootingKey.BROWSER_SUPPORT;
    }
    this.updateCheckInChecklistState(browserDetectionCheck);
  };

  runNativePromiseSupportCheck = () => {
    const nativePromiseSupportCheck: SupportPageCheck = {
      id: 2,
      labelI18nKey: 'support.page.checks.native.promise.support',
      result: SupportPageCheckResult.RUNNING,
    };
    if (typeof Promise !== 'undefined') {
      nativePromiseSupportCheck.result = SupportPageCheckResult.OK;
    } else {
      nativePromiseSupportCheck.result = SupportPageCheckResult.FAILURE;
      nativePromiseSupportCheck.troubleShootingAnchor =
        TroubleshootingKey.NATIVE_PROMISES_NOT_SUPPORTED;
    }
    this.updateCheckInChecklistState(nativePromiseSupportCheck);
  };

  runWebCryptoSupportChecks = async () => {
    const webCryptoSupportChecks = {
      id: 3,
      labelI18nKey: 'support.page.checks.webcrypto.api.support',
      isSeparator: true,
    };
    this.updateCheckInChecklistState(webCryptoSupportChecks);
    const webCryptoDetected = window.crypto && window.crypto.subtle;
    const webCryptoApiDetectionCheck = {
      id: 3.1,
      labelI18nKey: 'support.page.checks.webcrypto.api.support.api.detection',
      result: webCryptoDetected
        ? SupportPageCheckResult.OK
        : SupportPageCheckResult.FAILURE,
      troubleShootingAnchor: webCryptoDetected
        ? undefined
        : TroubleshootingKey.WEBCRYPTO_MISSING,
    };
    this.updateCheckInChecklistState(webCryptoApiDetectionCheck);
    await this.runWebCryptoChecks('SHA-256', 3.2);
    await this.runWebCryptoChecks('SHA-512', 3.6);
  };

  runWebCryptoChecks = async (
    hashingAlgorithm: string,
    firstSupportPageCheckId: number,
  ) => {
    // >> *** Key Generation Support Check ***
    const webCryptoKeyGenerationCheck: SupportPageCheck = {
      id: firstSupportPageCheckId,
      labelI18nKey: 'support.page.checks.webcrypto.api.support.key.generation',
      labelI18nParameters: { hashingAlgorithm: hashingAlgorithm },
      result: SupportPageCheckResult.RUNNING,
    };
    let key: CryptoKeyPair = {} as CryptoKeyPair;
    this.updateCheckInChecklistState(webCryptoKeyGenerationCheck);
    try {
      key = await crypto.subtle.generateKey(
        {
          name: 'RSASSA-PKCS1-v1_5',
          modulusLength: 2048,
          publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
          hash: { name: hashingAlgorithm },
        },
        false,
        ['sign', 'verify'],
      );
      webCryptoKeyGenerationCheck.result = SupportPageCheckResult.OK;
    } catch (exception) {
      if (hashingAlgorithm === 'SHA-512') {
        webCryptoKeyGenerationCheck.result = SupportPageCheckResult.WARNING;
        webCryptoKeyGenerationCheck.resolutionHintI18nKey =
          'support.page.troubleshooting.sha512.not.supported';
        webCryptoKeyGenerationCheck.troubleShootingAnchor =
          TroubleshootingKey.SHA_512_NOT_SUPPORTED;
      } else {
        webCryptoKeyGenerationCheck.result = SupportPageCheckResult.FAILURE;
      }
    }
    this.updateCheckInChecklistState(webCryptoKeyGenerationCheck);

    // >> *** Digital Signature Generation Check ***
    const webCryptoSignatureGenerationCheck: SupportPageCheck = {
      id: firstSupportPageCheckId + 0.1,
      labelI18nKey:
        'support.page.checks.webcrypto.api.support.digital.signature.generation',
      labelI18nParameters: { hashingAlgorithm: hashingAlgorithm },
      result: SupportPageCheckResult.RUNNING,
    };
    this.updateCheckInChecklistState(webCryptoSignatureGenerationCheck);

    let signature: ArrayBuffer = new ArrayBuffer(0);
    try {
      signature = await window.crypto.subtle.sign(
        {
          name: 'RSASSA-PKCS1-v1_5',
          hash: hashingAlgorithm,
        },
        key.privateKey,
        getWebCryptoSecurity().stringToArrayBuffer('Hello World!'),
      );
      webCryptoSignatureGenerationCheck.result = SupportPageCheckResult.OK;
    } catch (exception) {
      if (hashingAlgorithm === 'SHA-512') {
        webCryptoSignatureGenerationCheck.result = SupportPageCheckResult.WARNING;
        webCryptoSignatureGenerationCheck.resolutionHintI18nKey =
          'support.page.troubleshooting.sha512.not.supported';
        webCryptoSignatureGenerationCheck.troubleShootingAnchor =
          TroubleshootingKey.SHA_512_NOT_SUPPORTED;
      } else {
        webCryptoSignatureGenerationCheck.result = SupportPageCheckResult.FAILURE;
        webCryptoSignatureGenerationCheck.troubleShootingAnchor =
          TroubleshootingKey.SIGNING_FAILED;
      }
    }
    this.updateCheckInChecklistState(webCryptoSignatureGenerationCheck);

    // >> *** Digital Signature Verification Check ***
    const webCryptoSignatureVerificationCheck: SupportPageCheck = {
      id: firstSupportPageCheckId + 0.2,
      labelI18nKey:
        'support.page.checks.webcrypto.api.support.digital.signature.verification',
      labelI18nParameters: { hashingAlgorithm: hashingAlgorithm },
      result: SupportPageCheckResult.RUNNING,
    };
    this.updateCheckInChecklistState(webCryptoSignatureVerificationCheck);

    try {
      const verificationSuccessful = window.crypto.subtle.verify(
        {
          name: 'RSASSA-PKCS1-v1_5',
          hash: hashingAlgorithm,
        },
        key.publicKey,
        signature,
        getWebCryptoSecurity().stringToArrayBuffer('Hello World!'),
      );

      verificationSuccessful
        .then(val => {
          if (val) {
            webCryptoSignatureVerificationCheck.result = SupportPageCheckResult.OK;
          } else {
            webCryptoSignatureVerificationCheck.result =
              SupportPageCheckResult.FAILURE;
            webCryptoSignatureVerificationCheck.troubleShootingAnchor =
              TroubleshootingKey.SIGNATURE_VERIFICATION_FAILED;
          }
        })
        .catch(exception => {
          webCryptoSignatureVerificationCheck.result =
            SupportPageCheckResult.WARNING;
          webCryptoSignatureVerificationCheck.resolutionHintI18nKey =
            exception.toString();
        });
    } catch (exception) {
      webCryptoSignatureVerificationCheck.result = SupportPageCheckResult.WARNING;
      webCryptoSignatureVerificationCheck.resolutionHintI18nKey =
        'support.page.troubleshooting.sha512.not.supported';
      webCryptoSignatureVerificationCheck.troubleShootingAnchor =
        TroubleshootingKey.SHA_512_NOT_SUPPORTED;
    }
    this.updateCheckInChecklistState(webCryptoSignatureVerificationCheck);
  };

  runIndexedDbChecks = async () => {
    const indexedDbSupportChecks: SupportPageCheck = {
      id: 4,
      labelI18nKey: 'support.page.checks.indexed.db.support',
      isSeparator: true,
    };
    this.updateCheckInChecklistState(indexedDbSupportChecks);

    const indexedDbInitialisationCheck = {
      id: 4.1,
      labelI18nKey: 'support.page.checks.indexed.db.initialise',
      result: SupportPageCheckResult.RUNNING,
    } as SupportPageCheck;
    this.updateCheckInChecklistState(indexedDbInitialisationCheck);

    try {
      await getWebCryptoSecurity().initialize();
      indexedDbInitialisationCheck.result = SupportPageCheckResult.OK;
    } catch (exception) {
      indexedDbInitialisationCheck.result = SupportPageCheckResult.FAILURE;
      indexedDbInitialisationCheck.troubleShootingAnchor =
        TroubleshootingKey.INDEXED_DB_INIT_FAILED;
    } finally {
      this.updateCheckInChecklistState(indexedDbInitialisationCheck);
    }

    const indexedDbReadCheck: SupportPageCheck = {
      id: 4.2,
      labelI18nKey: 'support.page.checks.indexed.db.read',
      result: SupportPageCheckResult.RUNNING,
    };
    this.updateCheckInChecklistState(indexedDbReadCheck);
    Promise.all([
      getWebCryptoSecurity().getCertificates(),
      getWebCryptoSecurity().dumpCertificates(),
    ])
      .then(() => {
        indexedDbReadCheck.result = SupportPageCheckResult.OK;
      })
      .catch(reason => {
        indexedDbReadCheck.result = SupportPageCheckResult.FAILURE;
        indexedDbReadCheck.troubleShootingAnchor =
          TroubleshootingKey.INDEXED_DB_READ_FAILED;
      })
      .finally(() => this.updateCheckInChecklistState(indexedDbReadCheck));

    const indexedDbContentCheck: SupportPageCheck = {
      id: 4.3,
      labelI18nKey: 'support.page.checks.indexed.db.content',
      result: SupportPageCheckResult.RUNNING,
    };
    this.updateCheckInChecklistState(indexedDbContentCheck);
    try {
      const certificates = await getWebCryptoSecurity().getCertificates();
      if (certificates.length > 0) {
        indexedDbContentCheck.result = SupportPageCheckResult.OK;
      } else {
        indexedDbContentCheck.result = SupportPageCheckResult.INFO;
        indexedDbContentCheck.resolutionHintI18nKey =
          'support.page.checks.resolution.import.certificates';
        indexedDbContentCheck.troubleShootingAnchor =
          TroubleshootingKey.INDEXED_DB_EMPTY;
      }
    } catch (exception) {
      indexedDbContentCheck.result = SupportPageCheckResult.FAILURE;
    }
  };

  runSecurityFeatureChecks = async () => {
    const securityFeatureChecks = {
      id: 5,
      labelI18nKey: 'support.page.checks.security.features',
      isSeparator: true,
    } as SupportPageCheck;
    this.updateCheckInChecklistState(securityFeatureChecks);
    // >> *** Create CSR Check ***
    const createCSRCheck = {
      id: 5.1,
      labelI18nKey: 'support.page.checks.security.features.generate.csr',
      result: SupportPageCheckResult.RUNNING,
    } as SupportPageCheck;
    this.updateCheckInChecklistState(createCSRCheck);

    try {
      await getWebCryptoSecurity().createSslAndSignCSRs(
        'prerequisiteCheck',
        'test',
        'test',
      );
      const certificate: IndexedDbCertificateEntry =
        await getWebCryptoSecurity().getCertificate(
          'prerequisiteCheck-SIGN',
          'test',
          'test',
        );

      if (certificate.certificate === 'placeholder') {
        createCSRCheck.result = SupportPageCheckResult.OK;
      } else {
        console.error('No placeholder entry found in IndexedDB');
        createCSRCheck.result = SupportPageCheckResult.FAILURE;
        createCSRCheck.troubleShootingAnchor =
          TroubleshootingKey.CSR_GENERATION_FAILED;
      }
    } catch (exception) {
      createCSRCheck.result = SupportPageCheckResult.FAILURE;
      createCSRCheck.troubleShootingAnchor =
        TroubleshootingKey.CSR_GENERATION_FAILED;
    }
    this.updateCheckInChecklistState(createCSRCheck);

    // >> *** Cleanup CSR Check ***
    const cleanupCSRCheck = {
      id: 5.2,
      labelI18nKey: 'support.page.checks.security.features.cleanup.csr',
      result: SupportPageCheckResult.RUNNING,
    } as SupportPageCheck;
    this.updateCheckInChecklistState(cleanupCSRCheck);
    try {
      await getWebCryptoSecurity().deleteCertificates('prerequisiteCheck');
      cleanupCSRCheck.result = SupportPageCheckResult.OK;
    } catch (exception) {
      cleanupCSRCheck.result = SupportPageCheckResult.FAILURE;
      cleanupCSRCheck.troubleShootingAnchor =
        TroubleshootingKey.CSR_GENERATION_FAILED;
    }
    this.updateCheckInChecklistState(cleanupCSRCheck);
  };

  renderChecksSummary = () => {
    const allDone: boolean = this.state.checklistChecks
      .filter(check => !check.isSeparator)
      .every(check => check.result !== SupportPageCheckResult.RUNNING);
    return (
      <p>
        <u>Browser User Agent:</u> {navigator.userAgent}
        <br />
        <u>WebCrypto Bundle Version:</u> {webCryptoVersion}
        <br />
        {allDone && (
          <h6>
            <b>
              <FormattedMessage id="support.page.checks.all.done" />
            </b>
          </h6>
        )}
        <ChecklistDownloadReportButton />
      </p>
    );
  };

  render() {
    const checks = this.state.checklistChecks.map(check => (
      <ChecklistNode
        check={check}
        onMoreInformationClicked={this.props.onMoreInformationClicked}
      />
    ));
    return (
      <>
        {checks}
        <hr />
        {this.renderChecksSummary()}
      </>
    );
  }
}

export default Checklist;
