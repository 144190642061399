import OpenAmCallbackEntry from './OpenAmCallbackEntry';
import TreeNode from './TreeNode';
import ImpersonationEntry from '../models/ImpersonationEntry';
import OpenAmMetaMessage from './OpenAmMetaMessage';

enum CallbackType {
  Metadata = 'MetadataCallback',
  Name = 'NameCallback',
  Password = 'PasswordCallback',
  HiddenValue = 'HiddenValueCallback',
  TextInputCallback = 'TextInputCallback',
  Choice = 'ChoiceCallback',
  Confirmation = 'ConfirmationCallback',
  TextOutput = 'TextOutputCallback',
}

export enum ImpersonationChoice {
  REFRESH = 0 /** Is default from OpenAm, no need to set */,
  SKIP = 1,
  IMPERSONATE = 2,
}

const CONFIRMATION_CALLBACK_OK = 0;
const CONFIRMATION_CALLBACK_CANCEL = 1;

export default class OpenAmMessage {
  authId: string;
  callbacks: Array<OpenAmCallbackEntry>;
  successUrl?: string;

  constructor(json: any) {
    this.authId = json.authId;
    this.callbacks = json.callbacks;
    this.successUrl = json.successUrl;
  }

  private isOathTokenVerifier(): boolean {
    const hiddenValueCallbacks = this.callbacks?.find(
      callback => callback.type === CallbackType.Name,
    );
    return (
      hiddenValueCallbacks?.output?.find(e => !!e)?.value ===
      'Enter verification code'
    );
  }

  private isOathRegistrationNode(): boolean {
    const hiddenValueCallbacks = this.callbacks?.find(
      callback => callback.type === CallbackType.HiddenValue,
    );
    return (
      hiddenValueCallbacks?.input?.find(e => !!e)?.value === 'mfaDeviceRegistration'
    );
  }

  private isEmailOtpNode(): boolean {
    const passwordCallbacks = this.callbacks?.find(
      callback => callback.type === CallbackType.Password,
    );
    return passwordCallbacks?.output?.find(e => !!e)?.value === 'One Time Password';
  }

  getTreeNode(): TreeNode {
    const metadataCallback = this.callbacks?.find(
      callback =>
        callback.type === CallbackType.Metadata && callback.output[0].value?.node,
    );
    if (!metadataCallback) {
      if (this.isOathTokenVerifier()) {
        return TreeNode.OathTokenVerifier;
      }
      if (this.isOathRegistrationNode()) {
        return TreeNode.OathRegistration;
      }
      if (this.isEmailOtpNode()) {
        return TreeNode.EmailOtp;
      }
      throw new Error('getTreeNode() - Invalid OpenAm message');
    }
    return metadataCallback.output[0].value?.node;
  }

  getCallbackValue(type: CallbackType, prompt: string): string {
    const callback = this.callbacks
      .filter(callback => callback.type === type)
      .find(callback => callback.output.some(output => output.value === prompt));
    const challenge = callback?.output?.find(
      output => output.name === 'value',
    )?.value;
    if (!challenge) {
      throw new Error('getCallbackValue() - Invalid OpenAm message');
    }
    return challenge as string;
  }

  getChallenge(): string {
    return this.getCallbackValue(CallbackType.HiddenValue, 'Challenge / Response');
  }

  getSslCertificate(): string {
    return this.getCallbackValue(CallbackType.HiddenValue, 'sslCert');
  }

  getSignCertificate(): string {
    return this.getCallbackValue(CallbackType.HiddenValue, 'signCert');
  }

  getCaCertificate(): string {
    return this.getCallbackValue(CallbackType.HiddenValue, 'caCert');
  }

  getUpdateChoiceTitle(): string {
    return this.getCallbackValue(CallbackType.HiddenValue, 'updateChoiceTitle');
  }

  getUpdateChoiceText(): string {
    return this.getCallbackValue(CallbackType.HiddenValue, 'updateChoiceText');
  }

  getUpdateChoiceButton(): string {
    return this.getCallbackValue(CallbackType.HiddenValue, 'updateChoiceButton');
  }

  getUpdateChoiceExpiryDays(): string {
    return this.getCallbackValue(CallbackType.HiddenValue, 'updateChoiceExpiryDays');
  }

  getUpdateChoiceUpdateEnforceDays(): string {
    return this.getCallbackValue(
      CallbackType.HiddenValue,
      'updateChoiceUpdateEnforceDays',
    );
  }

  getRemainingAttempts(): string | null {
    try {
      return this.getCallbackValue(CallbackType.HiddenValue, 'remainingAttempts');
    } catch (error) {
      return null;
    }
  }

  getMfaDeviceRegistration(): string {
    return this.getCallbackValue(CallbackType.HiddenValue, 'mfaDeviceRegistration');
  }

  setCallbackInput(
    callbackType: string,
    prompt: string,
    inputValue: string | number,
  ) {
    const callback = this.callbacks
      .filter(callback => callback.type === callbackType)
      .find(callback => callback.output.some(output => output.value === prompt));
    if (!callback?.input) {
      throw new Error('setCallbackInput() - Invalid OpenAm message');
    }
    callback.input[0].value = inputValue;
  }

  setCommonName(commonName: string) {
    this.setCallbackInput(CallbackType.Name, 'Common Name', commonName);
  }

  setSubjectAlternativeName(subjectAlternativeName: string) {
    this.setCallbackInput(
      CallbackType.Name,
      'Subject Alternative Name',
      subjectAlternativeName,
    );
  }

  setPassword(password: string) {
    this.setCallbackInput(CallbackType.Password, 'Password', password);
  }

  setChallengeResponse(challengeResponse: string) {
    this.setCallbackInput(
      CallbackType.HiddenValue,
      'Challenge / Response',
      challengeResponse,
    );
  }

  setCertificateSerialNumber(certificateSerialNumber: string) {
    this.setCallbackInput(
      CallbackType.HiddenValue,
      'Certificate Serial Number',
      certificateSerialNumber,
    );
  }

  setSignCSR(signCSR: string) {
    this.setCallbackInput(
      CallbackType.TextInputCallback,
      'Sign CSR (Base64)',
      signCSR,
    );
  }

  setSslCSR(sslCSR: string) {
    this.setCallbackInput(
      CallbackType.TextInputCallback,
      'SSL CSR (Base64)',
      sslCSR,
    );
  }

  setNewPassword(newPassword: string) {
    this.setCallbackInput(CallbackType.Password, 'New password', newPassword);
  }

  setOathToken(token: string) {
    this.setCallbackInput(CallbackType.Name, 'Enter verification code', token);
  }

  setEmailOtp(otp: string) {
    this.setCallbackInput(CallbackType.Password, 'One Time Password', otp);
  }

  setCertificateStorageConfirmation(confirmStorage: boolean) {
    this.setCallbackInput(
      CallbackType.Confirmation,
      '',
      confirmStorage ? CONFIRMATION_CALLBACK_OK : CONFIRMATION_CALLBACK_CANCEL,
    );
  }

  setUpdateChoiceConfirmation(confirmChange: boolean) {
    this.setCallbackInput(
      CallbackType.Confirmation,
      '',
      confirmChange ? CONFIRMATION_CALLBACK_OK : CONFIRMATION_CALLBACK_CANCEL,
    );
  }

  setResourceId(resourceId: string) {
    this.setCallbackInput(CallbackType.Name, 'Resource ID', resourceId);
  }

  setOuId(ouId: string) {
    this.setCallbackInput(CallbackType.Name, 'OU ID', ouId);
  }

  setDefaultUserId(defaultUserId: string) {
    this.setCallbackInput(CallbackType.Name, 'Default User ID', defaultUserId);
  }

  setUserId(userId: string) {
    this.setCallbackInput(CallbackType.Name, 'User ID', userId);
  }

  setImpersonationChoice(choice: ImpersonationChoice) {
    this.setCallbackInput(CallbackType.Choice, 'Choices', choice);
  }

  getOuList(): Array<ImpersonationEntry> {
    const callback = this.callbacks
      .filter(callback => callback.type === CallbackType.Metadata)
      .find(callback => callback.output[0].value.hasOwnProperty('ouList'));
    if (!callback) {
      throw new Error('Invalid OpenAm message');
    }
    return callback.output[0].value?.ouList || [];
  }

  getUserList(): Array<ImpersonationEntry> {
    const callback = this.callbacks
      .filter(callback => callback.type === CallbackType.Metadata)
      .find(callback => callback.output[0].value.hasOwnProperty('userList'));
    if (!callback) {
      throw new Error('Invalid OpenAm message');
    }
    return callback.output[0].value?.userList || [];
  }

  getMessages(): Array<OpenAmMetaMessage> | null {
    const metadataCallback = this.callbacks?.find(
      callback => callback.type === CallbackType.Metadata,
    );
    if (!metadataCallback) {
      throw new Error('getMessages() - Invalid OpenAm message');
    }
    return metadataCallback.output[0].value?.messages;
  }
}
